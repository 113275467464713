import React, {useState} from 'react';

import FileForm from './FileForm';
import Vault from './Vault';
import {StyledApp} from './_styled';

const App = () => {
    const [vault, setVault] = useState(undefined);

    return (
        <StyledApp>
            {!vault && <FileForm onVaultLoaded={onVaultLoaded} />}

            <Vault vault={vault} onClose={onVaultCleared} />
        </StyledApp>
    );

    function onVaultLoaded(data) {
        setVault(data);
    }

    function onVaultCleared() {
        setVault(undefined);
    }
};

export default App;
