import {createGlobalStyle} from 'styled-components';

export const mainColor = '#ac0072';
export const defaultTextColor = '#333333';
export const inputFieldBorder = '#d4d4d4';

const GlobalStyle = createGlobalStyle`

  html {
    background: rgb(245, 245, 245);
    height: 100%;
  }

  body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: ${defaultTextColor};
    min-width: 100%;
  }


  .pure-form input[type=password], .pure-form input[type=text]  {
    border: 1px solid ${inputFieldBorder};
    border-radius: 0;


    &:focus {
      border: 1px solid ${mainColor};
    }
  }
  
  
  button.pure-button {
    border-radius: 0;
    background-image:none;
    background:#ccc;
    
    &.pure-button-primary {
      background:${mainColor};
    }
  }
`;

export default GlobalStyle;
