import styled from 'styled-components';
import {defaultTextColor, inputFieldBorder, mainColor} from './_styledGlobal';

export const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledFileForm = styled.form`
    margin: 8px;
    background: #fff;
    padding: 16px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;

    input[type='file'] {
        display: none;
    }

    > * {
        margin: 2px 0;
    }
`;

export const StyledUserMessage = styled.div`
    padding: 12px 0;
    color: ${mainColor};
`;

export const StyledVault = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledVaultForm = styled.form`
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid ${inputFieldBorder};

    &.pure-form > input[type='text'] {
        border: none;
        box-shadow: none;
        padding: 0;
        background: transparent;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }
`;

export const StyledVaultEntryList = styled.div`
    margin: 12px 4px;
`;

export const StyledVaultEntry = styled.div`
    margin: 8px 0;
    padding: 8px;
    border-bottom: 1px solid ${inputFieldBorder};
`;

export const StyledVaultEntryLabel = styled.div`
    cursor: pointer;
    color: ${mainColor};
`;

export const StyledVaultEntryContent = styled.div`
    margin-top: 4px;
    margin-left: 20px;
`;
export const StyledVaultEntryContentFields = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        margin-top: 4px;
    }

    > :nth-child(odd) {
        width: 30%;
    }

    > :nth-child(even) {
        width: 70%;
    }
`;
export const StyledVaultEntryContentNotes = styled.div`
    margin-top: 4px;
    border: 1px solid #ccc;
    padding: 4px;
`;

export const Numeric = styled.span`
    color: ${mainColor};
    font-family: 'Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif';
`;
export const Alpha = styled.span`
    color: ${defaultTextColor};
    font-family: 'Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif';
`;
