/**
 * filters the given array of entries
 *
 * @param {object[]} entries
 * @param {string} query
 * @returns {*}
 */
export default function filter(entries, query) {
    if (!query) {
        return entries;
    }
    return entries.filter(filterSingle.bind(undefined, query.toLowerCase()));
}

/**
 *
 * @param {string} query The lowercase query
 * @param {object} entry
 * @returns {boolean} Returns true if the entry matches for the given query
 */
function filterSingle(query, entry) {
    try {
        return (
            entry.label.toLowerCase().includes(query) ||
            (entry.url && entry.url.toLowerCase().includes(query)) ||
            (entry.notes && entry.notes.toLowerCase().includes(query))
        );
    } catch (error) {
        return false;
    }
}
