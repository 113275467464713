import React from 'react';
import {Alpha, Numeric} from './_styled';

/**
 *
 */
const ColorCodedValue = ({value}) => (
    <React.Fragment>
        {[...value].map(
            (c, i) =>
                isNumChar(c) ? (
                    <Numeric key={i}>{c}</Numeric>
                ) : (
                    <Alpha key={i}>{c}</Alpha>
                )
        )}
    </React.Fragment>
);

function isNumChar(c) {
    return c >= '0' && c <= '9';
}

export default ColorCodedValue;
