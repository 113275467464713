import React, {useState, useRef} from 'react';

import {StyledFileForm, StyledUserMessage} from './_styled';
import readVaultFile from './readVaultFile';

const FileForm = ({onVaultLoaded}) => {
    const [pw, setPw] = useState('');
    const [userMessage, setUserMessage] = useState('');

    const [fileInputKey, setFileInputKey] = useState(
        getNewFileInputFieldKeyString()
    );
    const fileInputRef = useRef(null);

    return (
        <StyledFileForm
            className="pure-form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <input
                type="file"
                ref={fileInputRef}
                multiple={false}
                onChange={onFileSelected}
                key={fileInputKey}
            />

            <input
                value={pw}
                onChange={(evt) => setPw(evt.target.value)}
                type="password"
                placeholder="Password"
                autoComplete="new-password"
            />

            {pw && (
                <button
                    type="button"
                    className="pure-button pure-button-primary"
                    onClick={onSelectFileButtonClicked}
                >
                    Select File
                </button>
            )}

            {userMessage && (
                <StyledUserMessage>{userMessage}</StyledUserMessage>
            )}
        </StyledFileForm>
    );

    function onSelectFileButtonClicked() {
        setUserMessage('');
        fileInputRef.current.click();
    }

    function onFileSelected(evt) {
        if (evt?.target?.files && evt.target.files.length > 0 && pw) {
            const file = evt.target.files[0];
            readVaultFile(file, pw)
                .then(onVaultLoaded)
                .catch((err) => {
                    setUserMessage(err);
                    setFileInputKey(getNewFileInputFieldKeyString());
                    setPw('');
                });
        } else {
            setFileInputKey(getNewFileInputFieldKeyString());
            setPw('');
        }
    }

    function getNewFileInputFieldKeyString() {
        return `finp_${Math.random()}`;
    }
};

export default FileForm;
