import * as iocane from 'iocane/web';

/**
 *
 * @param file
 * @param clearTextPassword
 */
export default function readVaultFile(file, clearTextPassword) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContentRaw = e.target.result;
            iocane
                .createAdapter()
                .decrypt(fileContentRaw, clearTextPassword)
                .then((clearContent) => {
                    try {
                        resolve(JSON.parse(clearContent));
                    } catch (err) {
                        reject('Could not read file content!');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject('Could not read file with given password!');
                });
        };
        reader.readAsText(file);
    });
}
