import React from 'react';

import {
    StyledVaultEntry,
    StyledVaultEntryContent,
    StyledVaultEntryContentFields,
    StyledVaultEntryContentNotes,
    StyledVaultEntryLabel
} from './_styled';
import ColorCodedText from './ColorCodedText';

const VaultEntry = ({entry, expanded, onExpandedToggle}) => {
    if (!entry) {
        return null;
    }

    const notesLines = entry.notes && entry.notes.match(/[^\r\n]+/g);

    return (
        <StyledVaultEntry>
            <StyledVaultEntryLabel onClick={onExpandedToggle}>
                {entry.label}
            </StyledVaultEntryLabel>

            {expanded && (
                <StyledVaultEntryContent>
                    <StyledVaultEntryContentFields>
                        <div>Username:</div>
                        <div>{entry.username}</div>
                        <div>Url:</div>
                        <div>{entry.url}</div>
                        <div>Password:</div>
                        <div>
                            <ColorCodedText value={entry.password} />
                        </div>
                    </StyledVaultEntryContentFields>

                    {notesLines && (
                        <StyledVaultEntryContentNotes>
                            {notesLines.map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </StyledVaultEntryContentNotes>
                    )}
                </StyledVaultEntryContent>
            )}
        </StyledVaultEntry>
    );
};

export default VaultEntry;
