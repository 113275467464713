import React, {useState} from 'react';

import {StyledVault, StyledVaultEntryList, StyledVaultForm} from './_styled';
import VaultEntry from './VaultEntry';
import filter from './entryFilter';
import entrySort from './entrySort';

const Vault = ({vault, onClose}) => {
    const [expandedEntry, setExpandedEntry] = useState(undefined); // holds the id of the entry that is currently expanded
    const [searchQuery, setSearchQuery] = useState('');

    const [filteredEntries, setFilteredEntries] = useState([]);

    if (!vault?.entries || vault.entries.length < 1) {
        return null;
    }

    return (
        <StyledVault>
            <StyledVaultForm
                className="pure-form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={onQueryChange}
                />

                <div>{vault.entries.length} Entries loaded</div>

                <button
                    className="pure-button pure-button-primary"
                    onClick={onClose}
                >
                    Close!
                </button>
            </StyledVaultForm>

            <StyledVaultEntryList>
                {filteredEntries?.length > 0 &&
                    filteredEntries.map((entry) => (
                        <VaultEntry
                            key={entry.id}
                            entry={entry}
                            onExpandedToggle={() => setExpandedEntry(entry.id)}
                            expanded={entry.id === expandedEntry}
                        />
                    ))}

                {!filteredEntries ||
                    (filteredEntries.length < 1 && (
                        <div>Please search for a entry</div>
                    ))}
            </StyledVaultEntryList>
        </StyledVault>
    );

    function onQueryChange(evt) {
        const q = evt.target.value;
        setSearchQuery(q);

        if (q.length > 2) {
            const filtered = filter(vault.entries, q);
            const sorted = entrySort(filtered);
            setFilteredEntries(sorted);
        }
    }
};

export default Vault;
